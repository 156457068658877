// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Vendor node_modules 
var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');

// Vendor node_modules 
// require('@fancyapps/fancybox');
// require('flickity');

require('material-scrolltop');
require('slick-carousel');



// Scripts
require('./modules/scripts');


$('.c-slider-home').slick({
 dots: true,
 infinite: true,
 slidesToShow: 1,
 adaptiveHeight: true
});

$(document).ready(function(){
  $('#nav-icon2').click(function(){
    $(this).toggleClass('open');
    $('.header__menu').toggleClass('is-active e-fade-in');
    $('.header__top').toggleClass('is-active-mobile');
  });
});









